// =================================================================================================
// IMPORTS
// =================================================================================================

import { ColumnHelper, Header } from '@tanstack/react-table'
import { FC } from 'react'
import { ToolTipOverviewDashboard } from '../tooltip'
import { ColumnDef } from '@/common/table/base-components/column-def'
import { OverviewDataRow } from '@/models/overview/types'
import SubHeader from '@/modules/overview/components/overview-table/subHeader'
import { StyledOverviewDevicesSensorsColumn, StyledOverviewDevicesSensorsColumnIcon } from '@/modules/overview/styled'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import { ROUTES } from '@/shared/constants'
import { dataTestId } from '@/tests/testid'
import TableHeaderColumn from '@/shared/components/TableHeaderColumn'

// =================================================================================================
// UTILS
// ===============

const tooltipText: Record<string, string> = {
  sensors: 'Count of faulted sensors',
  devices: 'Count of faulted devices',
}

// =================================================================================================
// COLUMN DEF
// =================================================================================================

export const generateDevicesSensorsColumnDefinition = (columnHelper: ColumnHelper<OverviewDataRow>) =>
  columnHelper.accessor('deviceAndSensors', {
    id: 'deviceAndSensors',
    header: 'Device & Sensor Management',
  })

// =================================================================================================
// COLUMN DEF COMPONENT
// =================================================================================================

export const OverAllTableDevicesSensorsColumnDef: FC = () => <ColumnDef />

// =================================================================================================
// HEADER COMPONENT
// =================================================================================================

export const OverAllTableDevicesAndSensorsColumn: FC<{
  header: Header<OverviewDataRow, unknown>
}> = ({ header }) => (
  <TableHeaderColumn<OverviewDataRow> header={header} centerValue={true} withSorting={false}>
    <SubHeader titles={['Sensors', 'Devices']} gridProp="1fr 1fr" />
  </TableHeaderColumn>
)

// =================================================================================================
// CELL COMPONENT
// =================================================================================================

interface OverAllTableDevicesSensorsColumnCellProps {
  value: OverviewDataRow['deviceAndSensors']
  companyId: string
}

const OverAllTableDevicesSensorsColumnCell: FC<OverAllTableDevicesSensorsColumnCellProps> = ({ value, companyId }) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()
  return (
    <td>
      <StyledOverviewDevicesSensorsColumn>
        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['sensors']}>
            <a
              data-testid={dataTestId.overviewDevicesSensorsColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.DEVICES_AND_SENSORS, companyId)}
              className="cell-value"
            >
              <StyledOverviewDevicesSensorsColumnIcon feColor="white" feIcon="exclamation" feSize="sm" />
              {value.sensors}
            </a>
          </ToolTipOverviewDashboard>
        </div>

        <div className="content-container">
          <ToolTipOverviewDashboard message={tooltipText['devices']}>
            <a
              data-testid={dataTestId.overviewDevicesSensorsColumn}
              onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.DEVICES_AND_SENSORS, companyId)}
              className="cell-value"
            >
              <StyledOverviewDevicesSensorsColumnIcon feColor="white" feIcon="exclamation" feSize="sm" />

              {value.devices}
            </a>
          </ToolTipOverviewDashboard>
        </div>
      </StyledOverviewDevicesSensorsColumn>
    </td>
  )
}
export default OverAllTableDevicesSensorsColumnCell
