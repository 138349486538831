import { AnalysisReasonStringType, AssetRow, ReasonIndicatorData } from '@/models/analysisBacklog/types'
import { DevicesSensorsTableDeviceRowData } from '@/models/devicesAndSensors/types'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { DateFilterValue, FilterRowType } from '@/shared/components/GenericTableFilter/types'
import { AssetStatus, AssetStatusString } from '@/shared/models/types'
import { Row } from '@tanstack/react-table'

/**
 * Filters rows based on a text search string.

 * @template T - The type of the row data.
 * @param {Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {string} searchString - The search string to match against.
 * @returns {boolean} - `true` if the row matches the search string, `false` otherwise.
 */
const textSearchFilterFn = <T extends FilterRowType>(row: Row<T>, columnId: string, searchString: string): boolean => {
  const data = row.getValue<string | T[]>(columnId)
  if (Array.isArray(data)) {
    return data?.some((item) => 'name' in item && item.name.toLowerCase().includes(searchString.trim().toLowerCase()))
  }
  return data?.toLowerCase().includes(searchString.toString().trim().toLowerCase())
}
/**
 * Filters rows based on a date range and filter type.

 * @template T - The type of the row data.
 * @param {Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {DateFilterValue} dateFilterValue - An object containing the filter type, start date, and end date.
 * @returns {boolean} - `true` if the row matches the date filter criteria, `false` otherwise.
 */
const dateFilterFn = <T>(
  row: Row<T>,
  columnId: string,
  { dateFilterType, startDate, endDate }: DateFilterValue
): boolean => {
  const columnValue = new Date(row.getValue(columnId))
  const dateWithoutHours = columnValue
  dateWithoutHours.setHours(0, 0, 0, 0)

  /* v8 ignore next 3 */
  if (dateWithoutHours.getTime() === 0) {
    return false
  }

  switch (dateFilterType) {
    case 'before':
      return startDate ? dateWithoutHours.getTime() <= startDate.getTime() : false
    case 'between':
      return startDate && endDate
        ? dateWithoutHours.getTime() >= startDate.getTime() && dateWithoutHours.getTime() <= endDate.getTime()
        : false
    case 'after':
      return startDate ? dateWithoutHours.getTime() >= startDate.getTime() : false
    default:
      return false
  }
}
/**
 * Filters rows based on a list of status values.

 * @template T - The type of the row data, either `AssetRow` or `RecommendedAction`.
 * @param {Row<T>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {AssetStatus[]} filteredStatus - An array of status values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered statuses, `false` otherwise.
 */
const statusFilterFn = <T extends AssetRow | RecommendedAction>(
  row: Row<T>,
  columnId: string,
  filteredStatus: AssetStatusString[]
): boolean => {
  const columnValue = row.getValue<AssetStatus>(columnId)
  return filteredStatus.some((v) => v.toLowerCase() === columnValue.toLowerCase())
}
/**
 * Filters rows based on a list of analysis reason values.

 * @param {Row<AssetRow>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {AnalysisReasonStringType[]} filteredAnalysisReasons - An array of analysis reason values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered analysis reasons, `false` otherwise.
 */
const analysisReasonFilterFn = (
  row: Row<AssetRow>,
  columnId: string,
  filteredAnalysisReasons: AnalysisReasonStringType[]
): boolean => {
  const rowValue = row.getValue<ReasonIndicatorData[] | undefined>(columnId)

  if (!rowValue) {
    return false
  }

  return filteredAnalysisReasons.every((filteredAnalysisReasonValue) =>
    rowValue.some((rowValue) => {
      const reasonType = rowValue.type
      if ('severity' in rowValue && filteredAnalysisReasonValue === `${reasonType}_${rowValue.severity}`) {
        return true
      } else if (!('severity' in rowValue) && filteredAnalysisReasonValue === reasonType) {
        return true
      }
    })
  )
}
/**
 * Filters rows based on a list of device status values.

 * @param {Row<DevicesSensorsTableDeviceRowData>} row - The current row being filtered.
 * @param {string} columnId - The ID of the column to filter.
 * @param {DevicesSensorsTableDeviceRowData['status'][]} filteredStatus - An array of device status values to filter by.
 * @returns {boolean} - `true` if the row matches one of the filtered statuses, `false` otherwise.
 */
const deviceStatusFilterFn = (
  row: Row<DevicesSensorsTableDeviceRowData>,
  columnId: string,
  filteredStatus: DevicesSensorsTableDeviceRowData['status'][]
): boolean => {
  const columnValue = row.getValue<DevicesSensorsTableDeviceRowData['status']>(columnId)
  return filteredStatus.some((v) => v == columnValue)
}

export { textSearchFilterFn, dateFilterFn, statusFilterFn, analysisReasonFilterFn, deviceStatusFilterFn }
