import { reconfirmReport } from '@/api/paths/reportStatus'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { startAsyncPoller } from '@/store/asyncPoller/actions'
import { useAppDispatch } from '@/store/store'

const useReconfirmReport = (assetId: string | undefined) => {
  const throwError = useAsyncThrow()
  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const reconfirmButtonDispatch = useAppDispatch()

  return useApi(async () => {
    return reconfirmReport(
      {
        onSuccess: async () => {
          setAnalyticsCancelButtonBehavior({ isDisabled: true })
          reconfirmButtonDispatch(
            startAsyncPoller({
              pollerInterval: 2000,
              pollerTimeout: 10000,
              toastMessages: {
                startPollingMessage: 'Processing Reconfirm status request',
                timeoutPollingMessage:
                  'Reconfirm status request timed out. Please refresh the page and if this repeats, contact support.',
                failedPollingMessage: 'Reconfirm status action failed. Please contact support.',
              },
            })
          )
        },
        onError: async (error) => {
          throwError(new DarwinError(error))
        },
      },
      { assetId }
    )
  })
}

export default useReconfirmReport
