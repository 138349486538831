import React, { useEffect } from 'react'
import { SiteDetailsProvider, useSiteDetailsContext } from '@/contexts/siteDetails'
import ContractDetails from '@/modules/site-details/components/contract-details'
import TechnicalInformation from '@/modules/site-details/components/technical-information'
import useGetSiteDetails from '@/modules/site-details/hooks/useGetSiteDetails'
import { StyledSiteDetailsContainer, StyledSiteDetailsParentContainer } from '@/modules/site-details/styled'
import { LoadingType } from '@/shared/hooks/useApi'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import useGetSiteDetailsLogo from '@/modules/site-details/hooks/useGetSiteDetailsLogo'
import { Logo, SiteDetailsData } from '@/models/siteDetails/types'
import { dataTestId } from '@/tests/testid'
import { useParams } from 'react-router-dom'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import { FetchDataContextProvider, useFetchDataContext } from '@/contexts/common/fetchDataContext'

const SiteDetails: React.FC = () => {
  return (
    <SiteDetailsProvider>
      <FetchDataContextProvider>
        <StyledSiteDetailsContainer data-testid={dataTestId.siteDetails}>
          <InnerSiteDetails />
        </StyledSiteDetailsContainer>
      </FetchDataContextProvider>
    </SiteDetailsProvider>
  )
}

const InnerSiteDetails: React.FC = () => {
  const { siteDetailsData, logoURL, setSiteDetailsData, isSiteDetailsEdited } = useSiteDetailsContext()
  const { execute: getSiteDetails, status: getSitesDetailsStatus } = useGetSiteDetails()
  const { execute: getSiteDetailsLogo, status: getSitesDetailsLogoStatus } = useGetSiteDetailsLogo(
    siteDetailsData.logo?.fileId as Logo['fileId']
  )
  const siteDetailsDispatch = useAppDispatch()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()
  const { sid } = useParams()

  useEffect(() => {
    getSiteDetails({ loaderType: LoadingType.GLOBAL })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useDeepCompareEffect(() => {
    setFetchDataStatus(getSitesDetailsStatus)
  }, [getSitesDetailsStatus])

  useEffect(() => {
    setFetchDataFn(() => getSiteDetails)
  }, [getSiteDetails, setFetchDataFn])

  useEffect(() => {
    if (getSitesDetailsLogoStatus == 'success') {
      siteDetailsDispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSitesDetailsLogoStatus])

  useDeepCompareEffect(() => {
    if (siteDetailsData?.logo?.fileId && getSitesDetailsStatus === 'success') {
      getSiteDetailsLogo({ loaderType: LoadingType.GLOBAL })
    }
  }, [siteDetailsData.logo?.fileId, getSitesDetailsStatus])

  useCleanupStorage()

  useEffect(() => {
    if (logoURL && !isSiteDetailsEdited) {
      setSiteDetailsData({
        ...siteDetailsData,
        logo: {
          ...siteDetailsData.logo,
          fileURL: logoURL,
        },
      } as SiteDetailsData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoURL])

  return (
    <StyledSiteDetailsParentContainer feJustifyContent="space-between" feFlexDirection="row">
      <TechnicalInformation />
      <ContractDetails />
    </StyledSiteDetailsParentContainer>
  )
}

export default SiteDetails
