import { ReasonIndicatorData } from '@/models/analysisBacklog/types'
import ReasonIndicator from '@/modules/analysis-backlog/asset-table/reasonIndicator'
import { COLORS } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { trackAIStrikerClick } from '@/app/datadog-config'
import { ROUTES } from '@/shared/constants'

const reasonConfigMap = {
  band: (severity: string) => ({
    reasonText: `Band ${severity}`,
  }),
  diagnostics: (severity: string) => ({ reasonText: `DiagX ${severity}` }),
  protean: () => ({ reasonText: 'Protean alarm', reasonIcon: { iconColor: 'red', iconName: 'danger' } }),
  ai: (severity: string, externalURL: string) => ({
    reasonText: 'AI alarm',
    reasonIcon: { iconColor: 'red', iconName: 'danger' },
    withRedirect: true,
    redirectUrl: externalURL,
    onClick: trackAIStrikerClick,
  }),
  overall: (severity: string) => ({ reasonText: `Overall ${severity}` }),
  feedback: () => ({
    reasonText: 'Feedback',
    reasonIcon: { iconColor: 'blue', iconName: 'email' },
    withBadge: true,
  }),
  device_fault: () => ({
    reasonText: 'Device fault',
    reasonIcon: { iconColor: 'purple', iconName: 'sensor' },
    innerRedirectModule: ROUTES.DEVICES_AND_SENSORS,
  }),
  no_sensor_data: () => ({
    reasonText: 'No sensor data',
    reasonIcon: { iconColor: 'white', iconName: 'unknownOutline' },
    customStyle: {
      backgroundColor: COLORS.gray600,
    },
  }),
  no_data_24h: () => ({
    reasonText: 'No Measurements 24h',
    reasonIcon: { iconColor: 'purple', iconName: 'sensor' },
    innerRedirectModule: ROUTES.DEVICES_AND_SENSORS,
    reasonCount: 0,
  }),
}

interface AnalysisReasonProps {
  reason: ReasonIndicatorData
  assetName?: string
}

const AnalysisReason: FC<AnalysisReasonProps> = ({ reason, assetName }) => {
  const { type, count, severity, externalURL } = reason

  const config = reasonConfigMap[type]?.(severity, externalURL)

  return config && <ReasonIndicator reasonCount={count} reasonSeverity={severity} {...config} assetName={assetName} />
}

export default AnalysisReason
